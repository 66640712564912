import {ChevronDown} from '@wix/wix-ui-icons-common'
import classNames from 'classnames'
import React from 'react'
import {getMultiDayText, isMultiDay} from '../../../../../../../../../../commons/selectors/event'
import sc from '../../../../../../classes.scss'
import {MobileMonthlyEventDetails} from './details'
import s from './mobile-monthly-event.scss'
import {MobileMonthlyEventProps} from '.'

export class MobileMonthlyEvent extends React.PureComponent<MobileMonthlyEventProps> {
  handleEventClick = () => {
    const {event, currentDate, openMonthlyCalendarEvent} = this.props
    openMonthlyCalendarEvent(event.id, currentDate)
  }

  renderShortEventInfo() {
    const {t, state, event, currentDate, eventTime} = this.props

    const multiDay = isMultiDay(state, event)
    const multiDayText = getMultiDayText(state, event.id, currentDate, t)

    return (
      <div
        className={classNames(s.container, sc.textLineHeight)}
        onClick={this.handleEventClick}
        data-hook={`event-${event.id}`}
      >
        <div className={s.time} data-hook={`event-time-${event.id}`}>
          {multiDay ? multiDayText : eventTime}
        </div>
        <div className={s.title} data-hook={`event-title-${event.id}`}>
          {event.title}
          <div className={s.chevron}>
            <ChevronDown size="1.2em" />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {t, selectedEventId, selectedDate, currentDate, event} = this.props

    if (selectedEventId === event.id && currentDate === selectedDate) {
      return <MobileMonthlyEventDetails t={t} event={event} />
    } else {
      return this.renderShortEventInfo()
    }
  }
}
