import {getFormattedFullLocation, hasImage} from '@wix/wix-events-commons-statics'
import React from 'react'
import {getMultiDayText, isMultiDay} from '../../../../../../../../../commons/selectors/event'
import {EventImage} from '../../../../../event-image'
import {LinkToPage} from '../../../../../link-to-page'
import {Members} from '../../../../../members'
import {Ribbon} from '../../../../../ribbon'
import {RsvpButton} from '../../../../../rsvp-button'
import {SocialBar} from '../../../../../social-bar'
import {PopupHeader} from '../header'
import s from './monthly-calendar-event-details.scss'
import {MonthlyCalendarEventDetailsProps} from '.'

export class MonthlyCalendarEventDetails extends React.PureComponent<MonthlyCalendarEventDetailsProps> {
  onBack = () => this.props.closeMonthlyCalendarEvent()

  render() {
    const {
      state,
      dateText,
      selectedDate,
      event,
      dayEventCount,
      showMembers,
      fullDate,
      shareFontSize,
      closeMonthlyCalendarPopup,
      t,
      showRibbon,
    } = this.props

    const multiDay = isMultiDay(state, event)
    const multiDayText = getMultiDayText(state, event.id, selectedDate, t)

    return (
      <div className={s.container} data-hook="calendar-event-details">
        <PopupHeader
          eventDetails
          text={dateText}
          onClose={closeMonthlyCalendarPopup}
          onBack={dayEventCount > 1 ? this.onBack : undefined}
          t={t}
        />
        <div className={s.eventDetailsContent}>
          {hasImage(event) ? (
            <div className={s.image}>
              <EventImage event={event} noLQIP />
            </div>
          ) : null}
          {showRibbon ? <Ribbon event={event} className={s.ribbon} calendar /> : null}
          {multiDay ? <div className={s.multiDayTitle}>{multiDayText}</div> : null}
          <div className={s.title} data-hook="title">
            <LinkToPage event={event}>{event.title}</LinkToPage>
          </div>
          <div className={s.fullDate} data-hook="date">
            {fullDate}
          </div>
          <div className={s.location} data-hook="location">
            {getFormattedFullLocation(event)}
          </div>
          {showMembers ? (
            <div className={s.members} data-hook="members">
              <Members event={event} calendarLayout={true} />
            </div>
          ) : null}
          {event.description ? (
            <div className={s.description} data-hook="description">
              {event.description}
            </div>
          ) : null}
          <div className={s.share} data-hook="share">
            <div className={s.shareText}>{t('shareEventText')}</div>
            <SocialBar t={t} spacing={7} event={event} iconFontSize={shareFontSize} row hideTitle />
          </div>
        </div>
        <RsvpButton event={event} dataHook="rsvp-button" minWidth={88} minHeight={32} containerClassName={s.button} />
      </div>
    )
  }
}
