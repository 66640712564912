import {TFunction} from '@wix/yoshi-flow-editor'
import {getEventDateInformation} from '../../../../../../../../../../commons/selectors/event'
import type {openMonthlyCalendarEvent} from '../../../../../../../actions/calendar-layout'
import {getSelectedDate, getSelectedEventId} from '../../../../../../../selectors/calendar-layout'
import {State} from '../../../../../../../types/state'
import {AppProps} from '../../../../../../app/interfaces'
import {connect} from '../../../../../../runtime-context/connect'
import {MobileMonthlyEvent as MobileMonthlyEventPresentation} from './mobile-monthly-event'

export interface MobileMonthlyEventOwnProps {
  t: TFunction
  event: ExtendedEvent
  currentDate: string
}

export interface MobileMonthlyEventRuntimeProps {
  t: TFunction
  state: State
  selectedEventId: string
  currentDate: string
  selectedDate: string
  eventTime: string
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
}

export interface MobileMonthlyEventProps extends MobileMonthlyEventRuntimeProps, MobileMonthlyEventOwnProps {}

const mapRuntime = (
  {state, actions}: AppProps,
  ownProps: MobileMonthlyEventOwnProps,
): MobileMonthlyEventRuntimeProps => {
  const {t, currentDate, event} = ownProps

  return {
    t,
    state,
    selectedEventId: getSelectedEventId(state),
    currentDate,
    selectedDate: getSelectedDate(state),
    eventTime: getEventDateInformation(state, event.id).startTime,
    openMonthlyCalendarEvent: actions.openMonthlyCalendarEvent,
  }
}

export const MobileMonthlyEvent = connect<MobileMonthlyEventOwnProps, MobileMonthlyEventRuntimeProps>(mapRuntime)(
  MobileMonthlyEventPresentation,
)
